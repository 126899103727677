import "./WorkCardStyle.css"
import React from 'react'
import WorkCard from "./WorkCard"
import WorkCardData from "./WorkCardData"

import { Button, Typography } from "@mui/material"
import { AiOutlineProject } from "react-icons/ai"
import { Delete } from "@mui/icons-material"
import { FaRegSmileWink } from "react-icons/fa"
import { useDispatch } from "react-redux"


const Work= () => {
  return (
    <div className="word-container">
        <div className="project-container">
            {WorkCardData.map((val) => {
                return(
                    <WorkCard
                    id={val.id}
                    projectImage={val.imgsrc}
                    projectTitle={val.title}
                    description={val.text}
                    url={val.url}
                    technologies={val.techstack}
                    />
                )
            })}
        </div>
 
 
    </div>
  )
 }
 
export default Work;


