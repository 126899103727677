import "./WorkCardStyle.css"
import React from 'react'
import { Button, Typography } from "@mui/material"
import { AiOutlineProject } from "react-icons/ai"
import { Delete } from "@mui/icons-material"
import { FaRegSmileWink } from "react-icons/fa"
import { useDispatch } from "react-redux"
import {Link} from "react-router-dom";

export const WorkCard = ({
  url,
  projectImage,
  projectTitle,
  description,
  technologies,
  isAdmin = false,
  id,
}) => {
  return (
    <>
      <a href={url} className="project-card" target="black">
        <div className="project-title">
          <img src={projectImage} alt="Project" />
          <h2>{projectTitle}</h2>
        </div>
        <div className="pro-details">
          <Typography variant="h9">Tech Stack: {technologies}</Typography>
          <div className="pro-btns">
            <Link to={`/project/${id}`} className="btn">VIEW</Link>
               <a href={url} className="btn" target="_blank">Source</a>
           </div>
        </div>
      </a>
    </>
  );
};

export default WorkCard