import "./FooterStyle.css"
import React from 'react'
import {FaMailBulk, FaUniversity, FaLinkedin,FaGithub} from "react-icons/fa";
const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="left">
                <div className="University">
                    <h4>
                    <FaUniversity size={20} style={{color:"#fff", marginRight:"2rem"}}/>University of Waterloo
                    </h4>
                </div>
                <div className="Email">
                <h4>
                    <FaMailBulk size={20} style={{color:"#fff", marginRight:"2rem"}}/>04alyona4@gmail.com
                    </h4>

                </div>
            </div>
            <div className="right">
            <h4>About the company</h4>
            <p>This is me Alyona Vishnoi. I am a passionate software developer driven by motivation and desire to learn the field inside and out. </p>
            <div className="social">
            <a href="https://linkedin.com/in/alyona-vishnoi" target="_blank" rel="noreferrer">
            <FaLinkedin size={30} style={{color:"#fff", marginRight:"1rem"}}/>
            </a>
            <a href="https://github.com/alyona-vishnoi" target="_blank" rel="noreferrer">
            <FaGithub size={30} style={{color:"#fff", marginRight:"1rem"}}/>
            </a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer