import "./AboutContentStyle.css"
import React from 'react'
import {Link} from "react-router-dom";
import img1 from "../images/python.png"
import me from "../images/me.jpg"
const AboutContent = () => {
  return (
    <div className="About">
        <div className="Left">
            <div className="who">
                <h1>Who Am I?</h1>
                <p>Hi there! I'm Alyona Vishnoi, a Mathematics student at the University of Waterloo.
                    I'm a passionate software developer who is keen about learning more about
                     Artifical Intelligence, Machine Learning, and Web Development!
                </p>
                <h1> A message from me:</h1>
                <p>
                Welcome to my portfolio website! I'm a passionate individual with a natural inclination for leadership and an unwavering curiosity for new experiences. I thrive on taking initiative and embracing challenges, constantly seeking opportunities to learn and evolve. Exploring uncharted territories is my second nature—I relish stepping outside my comfort zone to discover new passions and expand my skillset. If you're in search of a determined and creative collaborator or simply want to discuss the latest trends in the ever-evolving world, I'm here for you! 
                </p>
                <Link to="/contact">
                    <button className="btn">Contact</button></Link>
                <div className="img-stack-top">
                <img src={img1} className="img" alt="true"></img>
            </div>
            </div>
        </div>
        <div className="Right">
            <div className="img-container">
                <img src={me} className="me" alt="true"></img>
            </div>
        </div>
    </div>
  )
}

export default AboutContent