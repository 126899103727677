import React from 'react'
import { Typography } from "@mui/material"
import WorkCardData from "./WorkCardData"
import "./ProjectDetails.css"
import { useNavigate, useParams } from "react-router-dom";
import BackgroundImg from '../components/BackgroundImg'
import Navbar from '../components/Navbar'

const ProjectDetails = () => {
  const { id } = useParams();
  const project = WorkCardData.find((p) => p.id === Number(id));
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/project');
  };
  const formattedDescription = project.text.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));

  return (
    <>
    <Navbar/>
    <BackgroundImg heading={project.title} subtitle={project.techstack}/>
      <div className="view-project" >
      <p>{formattedDescription}</p>
        <a href={project.url} target="_blank" rel="noopener noreferrer" className="btn">Source code</a>
        <button classname="back-button" variant="contained" onClick={handleBack}>Back</button>
        </div>
    </>
  );
};

export default ProjectDetails;