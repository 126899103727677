import "./InterestStyles.css"
import React from 'react'
import img2 from "../images/reactcolor.png"
const Interests = () => {
  return (
    <div className="interests">
    <div className="skills">
            <h1><u>My Technical Skills</u></h1>
            <div id="resp-table">
                <div className="resp-table-row">Languages:
                    <div className="table-body-cell">Python, C, C++, JavaScript, SQL, R, VBA</div>
                </div>
                <div className="resp-table-row">Developer Tools:
                    <div className="table-body-cell">Git, AWS, Bitbucket, Linux, Bash, Clang, GDB, Valgrind</div>
                </div>
                <div className="resp-table-row">Libraries/Frameworks:
                    <div className="table-body-cell">React, NodeJS, ExpressJS, MongoDB, AxiosJS, TensorFlow, Keras, Scikit-learn, Pandas, NumPy, Matplotlib, Seaborn, Cloudinary, Pygame, Tkinter</div>
                </div>
            </div>
            <div className="img-stack-bottom">
                <img src={img2} className="img2"alt="true"></img>
            </div>
        </div>
    </div>
  )
}

export default Interests