import "./welcomeStyle.css";
import {Link} from "react-router-dom";
import React from 'react'
const welcomepage = () => {
  return (
    <div className="Intro">
      <div className="mask">
      </div>
        <div className="content">
            <p>MATHEMATICS STUDENT AT UWATERLOO</p>
            <h1>HI, I'M ALYONA VISHNOI</h1>
            <div>
                <Link to="/project"
                className="btn">Projects</Link>
                <Link to="/contact"
                className="btn btn-light">Contact</Link>
            </div>
        </div>


    </div>
  )
}

export default welcomepage