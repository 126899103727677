import "./FormStyle.css"
import React from 'react'
const Form = () => {
  return (
    <div className="zaza">\
    <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/xzbqzpyl" method="post">
    <fieldset id="fs-frm-inputs">

        <label for="full-name">Name</label>
        <input type="text" name="name" id="full-name" placeholder="First and Last" required=""></input>

        <label for="email-address">Email</label>
        <input type="email" name="_replyto" id="email-address" placeholder="email@domain" required=""></input>

        <label for="message">Message</label>
        <textarea rows="6" name="message" id="message" placeholder="Type Your Message Here" required=""/>
        <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
    </fieldset>
    <input className="btn" type="submit" value="Submit"></input>
    </form>
    </div>
  )
}



export default Form