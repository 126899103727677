import "./BackgroundImgStyles.css"
import React from 'react'
class BackgroundImg extends React.Component{
    render(){
        return (
            <div className="Background-img">
                <div className="Heading">
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.subtitle}</p>
                </div>
            </div>
          )
    }
}
export default BackgroundImg