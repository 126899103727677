import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import BackgroundImg from '../components/BackgroundImg'
import AboutContent from '../components/AboutContent'
import Interests from '../components/Interests'
const About = () => {
  return (
    <div>
      <Navbar/>
      <BackgroundImg heading="ABOUT ME" subtitle="Get to know me a little bit better :)"/>
      <AboutContent/>
      <Interests/>
      <Footer/>
    </div>
  )
}
export default About